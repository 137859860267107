import { rolesList } from './rolesList';

/**
 * Permissions common to all OS users (including system admins).
 */
const allStaffUsersPermissions = {
  canViewDecisionFinalServiceType:    true,
  canViewDisputeInfo:                 true,
  canViewTorType:                     true,
  canEditConsumerComplaintDetails:    true,
  canAddEvidenceAnytime:              true,
  canCompleteReplyTasks:              true,
  canMarkOwnTaskAsDone:               true,
  canViewDecisionWhitemail:           true,
  canViewDeceasedIndicator:           true,
  canViewBestContactFields:           true,
  canViewCommunicationRequirement:    true,
  canViewResolutionChallengeAccepted: true,
  canSkipCommunicationLogs:           true,
  canViewAllNotListedAppeals:         true,
  canEditSiteIssue:                   true,
  canEditSupplierCategorisation:      true,
}

/**
 * Permissions common to all SOR roles.
 */
const allSorPermissions = {
  ...allStaffUsersPermissions,
  canViewDreDashboards:               true,
  allowedToSeeOSSignUp:               true,
  canCheckResolutionChallenged:       true,
  canAcceptDeclineDispute:            true,
  canViewDisputeInfo:                 true,
  canCommentOnCompanyEvidence:        true,
  canViewUnpublishedDecision:         true,
  canRespondToAnyDispute:             true,
  canMaintainAndChangeAppealDecision: true,
  canViewDecisionFinalServiceType:    true,
  canEditReferralEvidenceIncluded:    true,
  canEditGovernmentSupportScheme:     true,
  canEditCompanyResponse:             true,
  canEditConsumerResponse:            true,
  canAddConsumerComments:             true,
  canMarkAnyTaskAsDone:               true,
  canMarkEvidenceAsChecked:           true,
  canForceUpdateUser:                 true,
  canEditCommunicationLogText:        true,
  canEditNextActionAt:                true,
  canEditCaseStatus:                  true
}

/**
 * For permissions checks not covered by view permissions.
 *
 * Rather than have permissions checks and role groupings that are spread across the codebase,
 * combine in a single point of truth - in a similar way to how the permissions_roles table is used.
 */
const rolePermissions = {
  [rolesList.COMPANY_USER]:                    {
    canViewUnpublishedDecision: true,
    canRespondToAnyDispute:     true,
    canViewDisputeInfo:         true,
    canEditCompanyResponse:     true,
    canMarkOwnTaskAsDone:       true,
    canViewDeceasedIndicator:   true
  },
  [rolesList.CONSUMER]:                        {
    allowedToCreateOwnNewCase:          true,
    canCommentOnCompanyEvidence:        true,
    canAddConsumerComments:             true,
    canMarkOwnTaskAsDone:               true,
    canViewBestContactFields:           true,
    canViewCommunicationRequirement:    true,
    canViewResolutionChallengeAccepted: true
  },
  [rolesList.ADMIN]:                           {
    ...allStaffUsersPermissions,
    allowedToSeeOSSignUp:            true,
    canViewAdmin:                    true,
    allowedToImpersonateUser:        true,
    canCheckResolutionChallenged:    true,
    canAcceptDeclineDispute:         true,
    canCommentOnCompanyEvidence:     true,
    canViewUnpublishedDecision:      true,
    canRespondToAnyDispute:          true,
    canEditNextActionAt:             true,
    canEditReferralEvidenceIncluded: true,
    canEditCompanyResponse:          true,
    canEditConsumerResponse:         true,
    canAddConsumerComments:          true,
    canMarkAnyTaskAsDone:            true,
    canForceUpdateUser:              true,
    canEditCommunicationLogText:     true,
    canEditCaseStatus:               true
  },
  [rolesList.OS_ADMIN]:                        {
    ...allStaffUsersPermissions,
    allowedToSeeOSSignUp:            true,
    canCommentOnCompanyEvidence:     true,
    canEditConsumerResponse:         true,
    canAddConsumerComments:          true,
    canForceUpdateUser:              true,
    canRespondToAssignedCaseDispute: true,
    canEditCommunicationLogText:     true
  },
  [rolesList.OS_SENIOR_ADMIN]:                 {
    ...allStaffUsersPermissions,
    allowedToSeeOSSignUp:            true,
    canCheckResolutionChallenged:    true,
    canAcceptDeclineDispute:         true,
    canCommentOnCompanyEvidence:     true,
    canEditNextActionAt:             true,
    canEditConsumerResponse:         true,
    canAddConsumerComments:          true,
    canMarkEvidenceAsChecked:        true,
    canForceUpdateUser:              true,
    canRespondToAssignedCaseDispute: true,
    canEditCommunicationLogText:     true
  },
  [rolesList.OS_INVESTIGATIVE_OFFICER]:        {
    ...allStaffUsersPermissions,
    canViewUnpublishedDecision:         true,
    canMaintainAndChangeAppealDecision: true,
    canEditReferralEvidenceIncluded:    true,
    canEditCompanyResponse:             true,
  },
  [rolesList.OS_SENIOR_INVESTIGATIVE_OFFICER]: {
    ...allStaffUsersPermissions,
    canViewUnpublishedDecision:         true,
    canMaintainAndChangeAppealDecision: true,
    canEditNextActionAt:                true,
    canEditReferralEvidenceIncluded:    true,
    canEditCompanyResponse:             true,
  },
  [rolesList.OS_DATA_OFFICER]:                 {
    ...allStaffUsersPermissions,
    allowedToSeeOSSignUp:            true,
    canViewUnpublishedDecision:      true,
    canEditReferralEvidenceIncluded: true,
    canEditCompanyResponse:          true,
    canEditConsumerResponse:         true,
    canAddConsumerComments:          true
  },
  [rolesList.DRE_ONE]:                         {
    ...allSorPermissions
  },
  [rolesList.DRE_TWO]:                         {
    ...allSorPermissions
  },
  [rolesList.DRE_THREE]:                       {
    ...allSorPermissions
  },
  [rolesList.LEADER]:                          {
    ...allSorPermissions,
    allowedToImpersonateUser: true,
  },
  [rolesList.COACH]:                           {
    ...allSorPermissions,
    allowedToImpersonateUser: true,
  }
};
export default rolePermissions;
